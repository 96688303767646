<template>
  <div
      style="display: flex;flex-direction: row;height: 60px;line-height: 30px;text-align: center;justify-content: flex-start">
    <txydian style="margin: 10px"></txydian>
    <p style="color: rgb(8, 145, 213); font-size: 20px; line-height: 30px; height: 30px; margin: 17px 0 0 10px;">
      {{data.title}}</p><span style="line-height: 30px;margin: 20px">{{data.en_title}}</span>
  </div>
</template>

<script>
import Txydian from "@/components/txydian";
export default {
  name: "textLabel",
  components:{
    Txydian
  },
  props:{
    data:{
      title:"",
      en_title:"",
    }

  }
}
</script>

<style scoped>

</style>
