<template>
  <div>
    <text-label :data="labeldata"></text-label>
    <el-image :src="value.image" fit="cover" style="height: 50%;width: 50%">
    </el-image>
    <div style="text-align: left;margin-left: 20px">
    <p v-if="value.companyName">{{ value.companyName }}</p>
    <p v-if="value.address">地址：{{ value.address }}</p>
    <p v-if="value.phone">电话：{{ value.phone }}</p>
    <p v-if="value.fax">传真：{{ value.fax }}</p>
    <p v-if="value.email">邮箱：{{ value.email }}</p>
    <p v-if="value.interAddress">网站：{{ value.interAddress }}</p>
    </div>
  </div>
</template>

<script>
import TextLabel from "@/components/textLabel";
export default {
  name: "About_us",
  components: {TextLabel},
  props:{
    value:{
      type:Object,
      default(){
        return{
          companyName:"",
          address:"",
          phone:"",
          fax:"",
          email:"",
          interAddress:""
        }
      }
    }
  },
  data(){
    return {
      labeldata:{
        title:"联系我们",
        en_title:"CONTACT US"
      },
    }
  }
}
</script>

<style scoped>

</style>
