<template>
  <div>
    <el-container>
      <el-header style="padding: 0;margin: 0;width: 100%" height="190px">
        <template>
          <heard></heard>
        </template>
      </el-header>
      <el-main style="padding: 0">
        <template>
          <div style="border-top: 10px solid deepskyblue;width: 100%">
            <carousel :images="images?images:[]" height="450px"></carousel>
          </div>
        </template>
        <template>
          <div class="basic_container">
            <book-tag v-for="(content,index) in this.getContext" class="book_tag"
                      :content="content" :key="index"></book-tag>
          </div>
        </template>
        <template v-if="this.indexView.productDisplaySwitch==1">
          <div class="basic_container_noflex">
            <div style="position:relative;margin: 0 auto;padding-top: 110px">
              <label style="font-size: 50px;color: #0891d5;padding-bottom:10px;display: inline-block;font-family: 新宋体 ">PRODUCTS</label>
              <label
                  style="font-size: 50px;margin-left: 50px;color: #0891d5;padding-bottom:10px;display: inline-block;font-family: 新宋体">CENTER</label>
            </div>
            <el-divider>
              <div style="position:relative;margin: 0 auto;border: 1px solid #eaeaea">
                <label
                    style="display: inline-block;color: #0891d5;padding: 10px 15px;font-size: 30px">欢迎新老客户前来选择我公司产品</label>
              </div>
            </el-divider>
          </div>
        </template>
        <template v-if="this.indexView.productDisplaySwitch==1">
          <div class="basic_container_noflex" style="margin-top: 100px">
            <product_item @clickDetail="goDetail" :product-basic-info="productBasicInfo"></product_item>
          </div>
        </template>
        <template>
          <div class="basic_container">
            <div style="width: 750px;margin-top: 30px;border: 1px solid gainsboro;">
              <customer-message :value="customerInfo"></customer-message>
            </div>
            <div style="width: 300px;margin: 30px 0 0 20px;border: 1px solid gainsboro;">
              <about_us :value="aboutUs"></about_us>
            </div>
          </div>
        </template>
        <template>
          <top-tag></top-tag>
        </template>
        <template>
          <top-message></top-message>
        </template>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import BookTag from "@/components/BookTag";
import Product_item from "@/components/product_item";
import Heard from "@/components/Heard";
import CustomerMessage from "@/components/CustomerMessage";
import About_us from "@/components/AboutUs";
import TopTag from "@/components/TopTag";
import TopMessage from "@/components/TopMessage";
import {httpAction} from "@/api/manage";

export default {
  name: "index",
  components: {TopTag, About_us, CustomerMessage, Heard, Product_item, BookTag, Carousel, TopMessage},
  computed: {
    customerInfo() {
      return Object.assign({}, {
        companyMessage: this.companyInfo.companyMessage,
        image: this.companyInfo.companyMessageImage
      })
    },
    aboutUs() {
      return Object.assign({}, {
        companyName: this.companyInfo.companyName,
        address: this.companyInfo.companyAddress,
        phone: this.companyInfo.companyPhone,
        fax: this.companyInfo.companyFax,
        email: this.companyInfo.companyEmail,
        interAddress: this.companyInfo.companyUrl,
        image: this.companyInfo.companyLinkImage
      })
    },
    getContext() {
      let temp = []
      if (this.contents) {
        temp = this.contents
        temp.push(this.contents[0])
      }
      return temp
    }
  },
  data() {
    return {
      images: [],
      indexView: {
        contentColumn: [],
        customerMessageSwitch: "",
        indexImages: [],
        linkTypeSwitch: "",
        productDisplaySwitch: "",
        productIndexList: []
      },
      companyInfo: {
        companyAddress: "",
        companyEmail: "",
        companyFax: "",
        companyLinkImage: "",
        companyMessage: "",
        companyMessageImage: "",
        companyName: "",
        companyOver: " ",
        companyOverImage: "",
        companyProductServer: "",
        companyProductServerImage: "",
        companyPhone: "",
        companyUrl: ''
      },
      contents: [
        {
          title: "",  //标题
          title_en: "", //标题英文
          images: [], //展示图片
          text_context: [], //文本内容
        }
      ],
      productBasicInfo: [],
      label_items: [],
      urls: {
        initIndex: "/index/getIndexConfig",
        companyInfo: "/companyManage/getCompanyInfo"
      }
    }
  },
  created() {
    this.initView()
  },
  methods: {
    initView() {
      httpAction(this.urls.initIndex, "", "get").then(res => {
        if (res.code == 0) {
          Object.assign(this.indexView, res.data)
          if (this.indexView.indexImages) {
            this.images = this.indexView.indexImages.map((item, index) => {
              return item.fileUrl
            })
          }
          return httpAction(this.urls.companyInfo, {body: {}});
        }
      }).then(res1 => {
        if (res1.code == 0) {
          Object.assign(this.companyInfo, res1.data)
          this.loadLanMu();
          if (this.indexView.productDisplaySwitch == 1) {
            this.loadProductList();
          }
          this.loadLabelItems()
        }
      })

    },
    loadLanMu() {
      const {contentColumn} = this.indexView
      if (this.indexView.contentColumn) {
        let switchCh = contentColumn.map((item, index) => {
          if (item.status == 1) {
            return item
          }
        })
        if (switchCh) {
          this.contents = [];
          switchCh.forEach(data => {
            if (data.itemText == "锦荣简介") {
              let companyOver = this.companyInfo.companyOver;
              if (this.companyInfo.companyOver.indexOf("<p>") != -1) {
                companyOver = this.companyInfo.companyOver.replaceAll("<p>", "")
              }
              let content = {
                title: data.itemText,
                title_en: "COMPANY OVER",
                images: this.companyInfo.companyOverImage.indexOf(",") !== -1 ? this.companyInfo.companyOverImage.split(",") : [this.companyInfo.companyOverImage],
                text_context: [companyOver],
                sort: 0,
                more: {text: "了解更多", path: "/aboutus/companyProfile"}
              }
              this.contents.push(content)
            } else if (data.itemText == "产品服务") {
              let content = {
                title: data.itemText,
                title_en: "PRODUCT SERVER",
                images: this.companyInfo.companyProductServerImage.indexOf(",") !== -1 ? this.companyInfo.companyProductServerImage.split(",") : [this.companyInfo.companyProductServerImage],
                text_context: [this.companyInfo.companyProductServer],
                sort: 1,
                more: {text: "了解更多", path: "/aboutus/companyProfile"}
              }
              this.contents.push(content)
            }
          })
        }
      }
    },
    loadProductList() {
      const {productIndexList} = this.indexView;
      if (productIndexList) {
        this.productBasicInfo = productIndexList
      } else {
        this.indexView.productDisplaySwitch = 0
      }
    },
    loadLabelItems() {
      this.label_items = [
        {
          text: "ADDRESS",
          text_Context: this.companyInfo.companyAddress,
          icon: require("@/assets/address_icon.png"),
          border_left: true,
        }, {
          text: "CALL US",
          text_Context: "电话:" + this.companyInfo.companyPhone,
          icon: require("@/assets/phone_icon.png"),
          border_left: true,
        },
        {
          text: "EMAIL",
          text_Context: this.companyInfo.companyEmail,
          icon: require("@/assets/email_icon.png"),
          border_left: false,
        }
      ]
    },
    goDetail(item) {
      if (item.id) {
        this.$router.push({path: "/product", query: {id: item.id}})
      }
    }
  }
}
</script>

<style scoped>

.basic_container {
  width: 1080px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 10px auto;
}

.basic_container_noflex {
  width: 1080px;
  position: relative;
  margin: 10px auto;
}

.border_left_line {
  border-right: 1px solid gray;
}

.book_tag {
  margin: 30px 28px;
  width: 300px;
}

</style>
