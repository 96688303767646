<template>
  <div style="display: flex;flex-direction: row;flex-wrap: wrap">
    <div v-for="(item, index) in productBasicInfo" :key="index" @click="goProductDetail(item)"
         style="margin:20px 10px;"
         :style="{height:me_style.height,width:me_style.width}">
      <el-card :body-style="{ padding: '0',height:me_style.height,width:me_style.width}">
        <el-image fit="contain"
                  :src="item.indexImage" style="width: 100%;height: calc(100% - 50px)"
                  class="image"/>
        <div style="padding: 14px;">
          <span>{{ item.name }}</span>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "product_item",
  props: {
    productBasicInfo: {
      type: Array,
      default() {
        return []
      }
    },
    me_style: {
      type: Object,
      default() {
        return {
          height: "250px",
          width: "250px"
        }
      }
    }
  },
  created() {

  },
  methods: {
    goProductDetail(item) {
      this.$emit("clickDetail", item)
    }
  }
}
</script>

<style scoped>
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
