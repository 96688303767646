<template>
  <div>
    <text-label :data="labelValue"></text-label>
    <p style="margin-left:20px;width: 720px; text-align: left;height:80px;overflow-y:scroll" class="scroll">
      {{ this.value.companyMessage}}
    </p>
    <div style="height: 280px;width: 640px;margin: 0 auto;position: relative">
      <el-form ref="form" :model="formDate" :rules="rules" label-width="80px" style="padding-top: 10px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="留言内容" prop="message">
              <el-input type="textarea" placeholder="请输入留言内容" v-model="formDate.message"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="姓名" prop="name">
              <el-input type="text" placeholder="请输入姓名" v-model="formDate.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="手机" prop="phone">
              <el-input type="text" placeholder="请输入手机号码" v-model="formDate.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item>
              <el-button type="primary" style="width: 80px;margin-bottom: 10px;" @click="submitForm">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-image fit="cover" style="position: absolute;right: 10px ;bottom: 10px;height: 170px;width: 240px" :src="this.value.image"></el-image>
    </div>
  </div>
</template>

<script>
import Txydian from "@/components/txydian"
import vaildPhoneTool from "@/utils/vaildPhoneTool";
import TextLabel from "@/components/textLabel";
import {httpAction} from "@/api/manage";

const validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入电话号码'))
  } else if (!vaildPhoneTool.isvalidPhone(value)) {
    callback(new Error('请输入正确手机号码'))
  } else {
    callback()
  }
}
export default {
  name: "CustomerMessage",
  components: {TextLabel, Txydian},
  props: {
    value: {
      type: Object,
      default() {
        return {
          companyMessage: "",
          image: ""
        }
      }
    },
  },
    data() {
      return {
        formDate: {
          message: "",
          name:"",
          phone:""
        },
        urls:{
          submit:"/message/saveMessage"
        },
        labelValue: {
          title: "客户留言",
          en_title: "CUSTOMER MESSAGE"
        },
        rules: {
          message: [
            {required: true, message: '请输入留言内容', trigger: 'blur'},
            // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
          ],
          name: [
            {required: true, message: '请输入姓名', trigger: 'blur'},
          ],
          phone: [
            {required: true, validator: validPhone, trigger: 'blur'},
          ],
        }
      }
    },
    methods: {
      submitForm() {
        this.$refs.form.validate((vail)=>{
          if(vail){
          httpAction(this.urls.submit,{body:this.formDate}).then(res=>{
            if(res.code==0){
              this.$alert(res.data, '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$refs.from.resetFields()
                }
              });
            }
          })
          }else {
            return false;
          }
        })
      }
    }
  }
</script>

<style>
.scroll::-webkit-scrollbar {
  width: 2px;
}

.el-form-item__content .el-textarea__inner {
  resize: none;
  height: 80px
}

</style>
