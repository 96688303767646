<template>
  <div style="width: 300px;border: 1px solid #e2e1e1">
    <div style="height: 50px;position: relative">
      <txydian style="position: absolute;left: 10px;margin-top: 5px"></txydian>
      <label
          style="font-size: 20px;color: #569d9d;line-height: 50px;position: absolute;left: 60px;display: inline-block">{{
          content.title
        }}</label>
      <label
          style="font-size: 16px;color: #676969;line-height: 50px;position: relative;left: 75px;display:inline-block">{{
          content.title_en
        }}</label>
    </div>
    <carousel v-if="content.images.length>1" height="180px" :images="content.images">
    </carousel>
    <el-image style="height: 180px" v-else :src="content.images?content.images[0]:''"></el-image>
    <div style="height: 200px;overflow-y:scroll" class="scroll">
      <ul v-if="content.text_context.length>1" style="text-align: left;list-style:none">
        <li v-for="(item,i) of content.text_context" :key="i" style="margin-top: 10px;position: relative">
          <span
              style="background-color: coral;height: 8px;width: 8px;border-radius: 50%;display: inline-block;margin-right: 10px;position: absolute;top: 8px"></span>
          <label v-if="item.url===''" class="class_label">
            {{ item.text }}
          </label>
          <el-link :underline="false" v-else :href="item.url" class="class_label link_hover" style="">{{
              item.text
            }}
          </el-link>
        </li>
      </ul>
      <div v-else style="text-align: left;font-size: 14px;margin-top: 10px;margin-left: 10px;height: 140px;overflow: scroll;
" class="scroll">
        {{ content.text_context[0] }}
      </div>
      <el-button v-if="this.content.more" @click="goPage" type="primary" size="round" round>
        {{ this.content.more.text }}
      </el-button>
    </div>

  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import Txydian from "@/components/txydian";

export default {
  name: "BookTag",
  components: {Txydian, Carousel},
  props: {
    content: {
      more: {
        type: Object,
        default: {
          text:"",
          path:""
        }
      },
      title: "",  //标题
      title_en: "", //标题英文
      images: [], //展示图片
      text_context: [{
        text: "",
        url: "",
      }], //文本内容
    }
  }, methods: {
    goPage() {
     this.$router.push(this.content.more.path)
    }
  }
}
</script>

<style scoped>
.scroll::-webkit-scrollbar {
  width: 2px;
}

.class_label {
  display: inline-block;
  width: 260px;
  position: relative;
  left: 20px
}

.link_hover:hover {
  color: coral;
}

</style>
