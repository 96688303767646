<template>
<div>



  <div style="height: 30px;width: 30px;border-radius: 50%;border: 2px solid #eeeded;display: flex;justify-content: center;align-items: center">
    <div style="background-color:#e3e2e2;height: 20px;width: 20px; border-radius: 50%">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "txydian",

}
</script>

<style scoped>

</style>
